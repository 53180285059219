const bcaLogo = require('../assets/img/payment/bca.png');
const briLogo = require('../assets/img/payment/bri.png');
const bniLogo = require('../assets/img/payment/bni.png');
const permataLogo = require('../assets/img/payment/permata.png');
const mandiriLogo = require('../assets/img/payment/mandiri.png');

const mastercardLogo = require('../assets/img/payment/mastercard.png');
const visaLogo = require('../assets/img/payment/visa.png');
const americanExpressLogo = require('../assets/img/payment/american-express.png');
const jcbLogo = require('../assets/img/payment/jcb.png');

const danaLogo = require('../assets/img/payment/dana.png');
const ovoLogo = require('../assets/img/payment/ovo.png');
const linkAjaLogo = require('../assets/img/payment/linkaja.png');
const shopeePayLogo = require('../assets/img/payment/shopeepay.png');
const shopeePayLaterLogo = require('../assets/img/payment/shopeepaylater.png');

const alfamartLogo = require('../assets/img/payment/alfamart.png');
const alfaexpressLogo = require('../assets/img/payment/alfaexpress.png');
const alfamidiLogo = require('../assets/img/payment/alfamidi.png');
const indomaretLogo = require('../assets/img/payment/indomaret.png');
const dandanLogo = require('../assets/img/payment/dandan.png');

const kredivoLogo = require('../assets/img/payment/kredivo.png');
const indodanaLogo = require('../assets/img/payment/indodana.png');

const jneLogo = require('../assets/img/shipping/jne.png');
const jntLogo = require('../assets/img/shipping/jnt.png');
const sicepatLogo = require('../assets/img/shipping/sicepat.png');

export const PAYMENT_METHODS = [
  {
    type: 'virtual-account',
    methods: [
      { label: 'BCA', logo: bcaLogo },
      { label: 'BRI', logo: briLogo },
      { label: 'BNI', logo: bniLogo },
      { label: 'Permata Bank', logo: permataLogo },
      { label: 'Mandiri', logo: mandiriLogo },
    ],
  },
  {
    type: 'credit-card',
    methods: [
      { label: 'Master Card', logo: mastercardLogo },
      { label: 'VISA', logo: visaLogo },
      { label: 'American Express', logo: americanExpressLogo },
      { label: 'JCB', logo: jcbLogo },
    ],
  },
  {
    type: 'e-wallet',
    methods: [
      { label: 'DANA', logo: danaLogo },
      { label: 'OVO', logo: ovoLogo },
      { label: 'Link Aja', logo: linkAjaLogo },
      { label: 'ShopeePay', logo: shopeePayLogo },
      { label: 'ShopeePay Later', logo: shopeePayLaterLogo },
    ],
  },
  {
    type: 'retail',
    methods: [
      { label: 'Alfamart', logo: alfamartLogo },
      { label: 'Alfa Express', logo: alfaexpressLogo },
      { label: 'Alfamidi', logo: alfamidiLogo },
      { label: 'Indomaret', logo: indomaretLogo },
      { label: 'DAN+DAN', logo: dandanLogo },
    ],
  },
  {
    type: 'cardless-credit',
    methods: [
      { label: 'Kredivo', logo: kredivoLogo },
      { label: 'Indodana', logo: indodanaLogo },
    ],
  },
];

export const SHIPPING_METHODS = [
  { label: 'JNE', logo: jneLogo },
  { label: 'J&T', logo: jntLogo },
  { label: 'SiCepat', logo: sicepatLogo },
];
